import Vue from "vue";
import { Component, Prop } from "vue-property-decorator";
import { Currency } from "@/types/currency";

@Component({
  inheritAttrs: false,
  components: {
    CardSetLimitForm: () =>
      import("../../../../components/CardSetLimitForm/CardSetLimitForm.vue"),
  },
})
export default class CardSetLimitMenu extends Vue {
  @Prop({ type: Boolean, default: false }) private readonly disabled!: boolean;
  @Prop({ type: String, required: true })
  private readonly cardCurrency!: Currency;
  @Prop({ type: Number, required: true })
  private readonly cardBankId!: number;
  @Prop({ type: Number, required: true })
  private readonly cardId!: number;
  @Prop({ type: Number })
  private readonly cardDailyLimit?: number;
  @Prop({ type: Number })
  private readonly cardDailySpend?: number;
  @Prop({ type: Number })
  private readonly cardTotalLimit?: number;
  @Prop({ type: Number })
  private readonly cardTotalSpend?: number;

  private showedMenu = false;

  private onSuccessSetLimit() {
    this.toggleMenu(false);
    this.$emit("success:set-limit");
  }

  private toggleMenu(showed = !this.showedMenu) {
    this.showedMenu = showed;
  }
}
